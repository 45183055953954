// src/langurage/en_US.js
import bs_en_US from "@/components/BootStrap/en_US";

/**
 * 定义英文语言包对象
 */
export default {
  // Common Language Pack
  ...bs_en_US,
  common: {
    keyword:'Keyword',
    tips:"tips",
    // Dialog
    dialog: {
      title: "Prompt",
      defaultTitle: "Default Box",
      addTitle: "Add Box",
      editTitle: "Edit Box",
      detailTitle: "Detail Box",
      selectTitle: "Selection Box",
      preview: "preview",
      deleteTitle: "Delete Data",
      setDataNull: "Please select before performing the operation!",
      deleteTips:
        "This operation will permanently delete the data. Do you want to continue?",
      inquireTips:
        "Ticket personnel are initiating this Ticket process. Do you agree?",
      permissionTitle: "Modify Role Permissions",

      replyTitle: "Reply Box",
      eventTitle: "New Event",
      fillInLogisticsTitle: "Fill in Logistics",
      examineCheck: "Double-click to check",
      clickBox: "Click To View",
      roleTitle: "View Role Permissions",
    },
    message: {
      input: "Please enter",
      select: "Please select",

      errorDetail: "error details",
      errorDetail1:'Network problem, you can resubmit later',
      errorDetail2:'Account type problem, need to submit the corresponding support ticket',
      linkTo:'skip page',


      tipsTitle: "Prompt",

      SubmitAnApplication: "submit an application",
      toBeAssigned: "to be assigned",
      pendingProcessing: "pending processing",
      processing: "processing",
      completed: "completed",
      inProgress: "in progress",
      notStarted: "notStarted",

      successTitle: "Success",
      success: "Operation executed successfully",
      errorTitle: "Failure",
      error: "Operation execution failed",
      unknownTitle: "Unknown",
      unknown: "Unknown status. Please contact the administrator",
      message: "Message",

      addSuccess: "Added successfully",
      addError: "Addition failed",
      updateSuccess: "Modified successfully",
      updateError: "Modification failed",
      deleteSuccess: "Deleted successfully",
      deleteError: "Deletion failed",

      networkError: "Network error. Please try again later!",
      uploadError: "Uploaded avatar image must be in JPG/PNG format!",
      uploadSizeError: "Uploaded image size cannot exceed 2MB!",
      uploadNumError: "File upload exceeds limit!",

      uploadFileSizeError: "The size of the uploaded file cannot exceed 60MB!",
      uploadFileNumError:
        "Current upload limit Select {number} files, exceed the limit!",
      uploadFileFormatErr: "The format of the uploaded file is not allowed",

      sortNumber: "Sorting value must be a number",
      dictKeyAndValue: "Please enter dictionary key and value",
      isDefault:
        'Please switch "Non-main email" to "Main email" to automatically unselect other email\'s main status',

      copySuccess: "Copy successful",

      remoteLogin: "Remote Login",
      remoteLoginMsg:
        "The account has been logged in elsewhere, if not myself login, please change the password as soon as possible!",
      imgUploadMgs: "Click to upload the picture",

      setRoleMgs: "Select the role assigned by the user",
      txtFlow: "Private messages, visible only in the background!",
    },
    button: {
      backHome:"Back to home page",
      backChange:"Return to modify",
      Cancelled:'Canceled',
      home: "Go Home",
      login: "Login",
      register: "Sign In",

      search: "Search",
      reset: "Reset",
      resetLogin: "Reset the login form?",
      add: "Add",
      save: "Save",
      detail: "Detail",
      edit: "Edit",
      delete: "Delete",
      deletes: "Bulk Delete",
      modify: "Modify",
      send: "Send",
      confirm: "Confirm",
      done:"Done",
      confirmOk: "Ok",
      back: "Back",
      goBack: "Go Back",
      cancel: "Cancel",
      select: "Select",
      permission: "Permissions",
      progress: "Progress",
      record: "Invoice",
      care: "Transfer",
      allocation: "Allocate",
      appoint: "Assign Ticket",
      receive: "Receive Ticket",
      transfer: "Transfer Ticket",
      transfering: "Transferring",
      cancelTransfer: "Cancel Transfer",
      fillIn: "Fill In",
      check: "View",
      checkAll: "View All",
      after: "Event",
      reply: "Reply",
      close: "Close",
      ReplayAndClose: "Reply and close",
      end: "End",
      submit: "Submit",
      addCost: "Add Cost",
      accept: "Accept",
      refuse: "Refuse",
      option: "Option",
      selectAll: "Select All",
      prev: "Back",
      sign: "Sign",

      change: "Change",
      continue: "Continue",
      revoked: "Discard",
      share: "Share",
      generate: "Generate",
      copy: "Copy",
      jump: "Jump",
      restore: "Restore",

      resetPass: "Reset PassWord",
      setRole: "Assign Roles",

      read: "Mark All as Read",

      fillInAccount: "Fill in Account",
      checkAccount: "Check Account",

      importExcel: "Upload RMA Sheet",
      downloadExcel: "Download RMA Sheet",
      goTicket: "Submit a Support Ticket",

      uploadFile: "Upload File",
      total: "Total",

      uploadInvoice: "Upload Invoice",
      checkInvoice: "Check Invoice",
      ticketInvoice: "Ticket Invoice",

      mergeTicket: "Merge Ticket",
      reopen: "Reopen",

      downloadUrl: "Download Hub",
      flowCheck: "Filling Now",

      getCode: "Get OTP",
      isCode: "Validate",
    },
    table: {
      noData: "No data available",
      number: "Number",
      operation: "Operation",
      select: "Select",
      allocation: "Allocate",
    },
    loadingText: "Please wait, loading...",
    loginExpired: "Login expired. Please login again",
  },
  pullDown: {
    placeholder: "Select",
    unknown: "Unknown",
    noData: "No Data",
    sex: {
      secret: "Prefer Not To Say",
      man: "Male",
      woman: "Female",
      other: "Other",
    },
    userStatus: {
      enable: "Incumbency",
      disable: "Dimission",
    },
    daterange: {
      start: "Start Date",
      range: "to",
      end: "End Date",
    },
    resourceType: {
      parent: "Level one menu",
      page: "Page Route",
      button: "Action Button",
    },
    enable: {
      enable: "Enable",
      disable: "Disable",
    },
    messagePushTime: {
      goTime: "Time Push",
      goPlus: "Direct Push",
    },
    messageType: {
      complaint: "Notice of Complaints",
      system: "System notifications",
    },
    isDefault: {
      defaultMail: "Main Email",
      notDefaultMail: "Non-main Email",
    },
    afterStatus: {
      allocation: "New",
      pending: "Pending",
      processing: "Processing",
      completed: "Closed",
      revoked: "Cancelled",
      maintain: "Repairing",
    },
    // Translation
    expressageStatus: {
      processing: "awaiting signature",
      completed: "Sign for",

      accountNo: "To be filled",
      accountYes: "Fill out",
    },

    careofStatus: {
      processing: "In Transfer",
      completed: "Transfer Successful",
      refused: "Transfer Rejected",
      cancel: "Transfer Cancelled",
    },
    afterFromType: {
      privacy: "Privacy Policy",
      cancel: "Logout instruction",

      disclaimer: "Disclaimer",
      terms: "Terms and Conditions",
    },
    result: {
      success: "Success",
      error: "Error",
    },
    setUp: {
      sys: "System built-in",
      drop: "Pull Down Data",
    },
    whether: {
      yes: "Yes",
      no: "No",
    },
    accountState: {
      normal: "Normalcy",
      logout: "Cancell",
      lock: "Lock",
    },
  },
  excel: {
    importTitle: "Import Data",
    download: "Download Import Template",
    importTips: "Please upload an Excel file",
    importError: "File format is incorrect. Only xls/xlsx files are allowed!",

    dialog: "Error prompt for importing table",
    maxlog:
      "The maximum size of a table file is 60M. This file exceeds the maximum value. You can import it in batches",
    label: "Error Message",

    exportTitle: "Export Table",
    spuFile: "Product Information-",
  },
  // Settings
  system: {
    train: "Column Settings",
    frame: "Search Function",
  },
  // Translation
  elTag: {
    headerTitle: "Support - iFi",

    mdTitle: "Editor",
    editorTitle: "Rich Text Editor",
    editorTip: "Please enter your content...",

    confirmed: "Confirmed",
    expired: "Expired",
    expiredAff: "Process Ended",
  },
  // File
  file: {
    uploadLabel: "Upload File",
    uploadSidLabel: "Receipt Included？",
    text: "Drag files here or",
    textEm: "Click to upload",
    textTip: "You can upload up to 6 files at the most and each file should be in 60 mb.",
    extraInfo:'Other Information',
    fromLabel: "Attachment",
    fromText: "File",

    mgsFileNull: "Please upload the attachment first!",
invoice: "Invoice",
    table: "List Of Attachments",
    invoiceTable: "Attachment",
    name: "Name Of Annex",
    time: "Upload Time",
  },
  // 时间
  time: {
    just: "Just now",
    minute: "{num} Minutes ago",
    hour: "{num} Hours ago",
    today: "Today",
    yesterday: "Yesterday",
    day: "{num} Days ago",
    week: "{num} Weeks ago",
  },

  // ------ Login Page / Home Framework / Home Page / Personal Center ------
  // Login Page Language Pack
  LoginView: {
    // title: 'KaiYun iFi Ticket Platform',
    // titleBox: 'WeCome: iFi Ticket ',
    // content: 'Kaiyun iFi Ticket platform focuses on solving the Ticket problems of the products purchased by customers, and carries out the process tracking record processing management platform',

    tab1: "Account Password Login",
    login: "Log In",
    account: "Account",
    accountPlaceholder: "Enter your account",
    password: "Password",
    passwordPlaceholder: "Enter your password",

    titleUrl: "Navigation Link",
    clienteleAfter: "Customer",
    officialMall: "Franchise",
    officialWebsite: "Support",
    afterPlatform: "Document",
  },
  // Home Page Language Pack
  HomeView: {
    title: "Ticket Platform",
    home: "Home",
    language: {
      zh: "中文简体",
      en: "English",
    },
    languageDialog: {
      title: "Change Language",
      zhContent: "Change to chinese simplified. continue?",
      enContent: "Change to english simplified. continue?",
    },
    itemDropdown: {
      refresh: "Reload",
      close: "Close Tab",
      closeLeft: "Close tabs to the left",
      closeRight: "Close tabs to the right",
      closeAll: "Close all tabs",
      closeOther: "Close other tabs",
    },
    // 头像下拉菜单
    avatarDropdown: {
      profile: "Profile",
      setCache: "Refresh cache",
      clearCache: "Clear Cache",
      logout: "Log Out",
    },
    // 退出登录
    logOutDialog: {
      noPageAuth:"No access to this page yet",
      noAuthTip:"You do not have access to this ticket, please switch account",
      title: "Log Out",
      logoutTips: "Are you sure you want to log out?",
      logoutSuccess: "Logged out successfully!",
      logoutError: "Log out cancelled",
    },
    clearCache: "Clearing cache, please wait",
    error: "Sorry, the page you visited does not exist.",
    iconTitle: "Icon Library",
  },
  // Home Page Language Pack
  HomePageView: {
    user: {
      title: "Welcome",
    },
    dataPresentation: {
      title: "Data Presentation - Aftersales Tickets in Progress",
      radio: {
        my: "My",
        all: "All",
      },
      proportion: "Proportion",
      allProportion: "All Tickets Proportion",

      threeDayQuantity: "3-Day Processed Tickets",
      threeDayQuantityRemark: "Your 3-day (0-3) processed tickets",
      sevenDayQuantity: "3-7 Day Processed Tickets",
      sevenDayQuantityRemark: "Your 3-7 day (=3<=7) processed tickets",
      bigSevenDayQuantity: "7+ Day Processed Tickets",
      bigSevenDayQuantityRemark: "Your 7+ day (>7) processed tickets",
      allQuantity: "Total Processed Tickets",
      allQuantityRemark: "Total processed tickets you are responsible for",

      allThreeDayQuantity: "All 3-Day processed tickets",
      allThreeDayQuantityRemark: "All processed tickets in the past three days",
      allSevenDayQuantity: "All 3-7 day processed tickets",
      allSevenDayQuantityRemark:
        "All processed tickets in the past 3-7 days (=3<=7)",
      allBigSevenDayQuantity: "All 7+ day processed tickets",
      allBigSevenDayQuantityRemark:
        "All processed tickets in the past 7+ days (>7)",
      allAllQuantity: "Total Processed Tickets",
      allAllQuantityRemark: "Total processed tickets",
    },
    chart: {
      title: "Chart Statistics - Monthly Completed Support Tickets",
      radio: {
        halfYear: "Last Six Months",
        year: "Last Year",
      },
      my: "My completed support tickets",
      all: "All completed support tickets",
    },
    activities: {
      title: "Platform notices and announcements",
    },
    backLog: {
      title: "My To-Do List",
      description: "There is currently no data! Add a new item?",
      addButton: "Add To-Do Item",
      enableTag: {
        pending: "Pending",
        processing: "In Progress",
        completed: "Completed",
      },
      significanceTag: {
        mild: "Low",
        general: "Medium",
        important: "High",
      },
      titleDialog: {
        addTitle: "Add To-Do Item",
        updateTitle: "Edit To-Do Item",
      },
      formDialog: {
        title: "Title",
        titlePlaceholder: "Enter a title, maximum length is 16 characters",
        enable: "Status",
        enablePlaceholder: "Select the status of your to-do item",
        significance: "Importance",
        content: "Content",
        contentPlaceholder: "Enter the specific content of your to-do item",
      },
    },
  },
  // 主页样式抽屉
  StyHome: {
    title: "Home Style",
    // 全局样式
    wholeDivider: "Global Style",
    wholeTheme: "Dark Theme",
    // 顶部栏样式
    headerDivider: "Header Style",
    headerLogo: "Logo Display: ",
    headerTitle: "Title Display: ",
    headerBackgroundColor: "Header Background Color: ",
    headerTextColor: "Header Text Color: ",
    // 侧边栏样式
    menuDivider: "Menu Style",
    menuSty: "Menu Collapse: ",
    menuAccordion: "Menu Accordion: ",
    menuBackgroundColor: "Menu Background Color: ",
    menuTextColor: "Menu Text Color: ",
    menuActiveTextColor: "Menu Active Color: ",
    // 动态路由标签
    roueDivider: "Route Tags",
    roueDiv: "Dynamic Route Tags Hide: ",
    breadcrumb: "Breadcrumb: ",
    roueBackgroundColor: "Route Tag Background Color: ",
    roueTextColor: "Route Tag Text Color: ",
    roueActiveTextColor: "Route Tag Active Color: ",

    // Component Page Style
    trainFrameTitle: "Module Style",
    trainFrame: "Column and Query Settings: ",
    trainFrameDefault: "Settings (Restore Default): ",
    trainFrameDefaultButton: "Default",

    // 提示 是否将系统样式恢复默认设置?
    tips: "Are you sure you want to restore the system style to the default settings?",
  },
  // Personal Center Language Pack
  MyView: {
    labelMgs: "Recent News",
    userForm: {
      label: "Profile",
      title: "Welcome",
      account: "Account",
      accountPlaceholder: "Enter your account",
      nickName: "Name (En)",
      nickNamePlaceholder: "Enter your name (English)",
      name: "Name",
      namePlaceholder: "Enter your name (Chinese)",
      sex: "Gender",
      sexPlaceholder: "Select your gender",
      role: "Role",
      rolePlaceholder: "Select your role",
      region: "Region",
      regionPlaceholder: "Select your region",
      phone: "Phone",
      phonePlaceholder: "Enter your phone number",
      identityCard: "Agent Numbers",
      identityCardPlaceholder: "Please enter your agent number",
      birthDate: "Birth Date",
      birthDatePlaceholder: "Select your date of birth",
      userStatus: "Status",
      userStatusPlaceholder: "Select user status",
      weixin: "WeChat",
      weixinPlaceholder: "Enter your WeChat ID",
      qq: "QQ",
      qqPlaceholder: "Enter your QQ ID",
      email: "Email",
      emailPlaceholder: "Enter your email",
    },
    updatePass: {
      label: "Change Password",
      account: "Account",
      password: "Password",
      passwordPlaceholder: "Enter your old password",
      newPassword: "New Password",
      newPasswordPlaceholder: "Enter your new password",
      newTowPasswordPlaceholder: "Re-enter your new password",
      passwordLength: "Password length should be between 6 and 20 characters",
      passwordInconsistent: "Passwords entered do not match!",
      passValid:
        "Must be numeric, alphabetic, special characters and no less than 8 digits!",
    },
  },

  // ------ Basics: System Management ------
  // User Management
  UserView: {
    accountState: "Status",

    userData: "UserData",
    getAvatar: "Get Avatar",
    getAvatarIs: "The user English name cannot be empty!",

    nameQuery: "Name",
    nameQueryPlaceholder: "Enter user name (Zh/En)",
    nameValid:
      "Consists of numbers, Chinese, English, spaces, 15 characters or less!",
    phoneQuery: "Phone/Email",
    phoneQueryPlaceholder: "Enter phone number or email",
    weixinQuery: "WeChat/QQ",
    weixinQueryPlaceholder: "Enter WeChat ID or QQ ID",

    account: "Account",
    accountPlaceholder: "Enter your account",
    password: "Password",
    passwordPlaceholder: "Enter your password",
    passwordLength: "Password length should be between 6 and 20 characters",
    passwordMgs:
      "The default password of the new user is the same as your account, and can be modified in the personal center later!",
    nickName: "Name (En)",
    nickNamePlaceholder: "Enter your Name (English)",
    name: "Name",
    namePlaceholder: "Enter your Name (Chinese)",
    sex: "Gender",
    sexPlaceholder: "Select your gender",
    role: "Role",
    rolePlaceholder: "Select your role",
    region: "Region",
    regionPlaceholder: "Select your region",
    phone: "Phone",
    phonePlaceholder: "Enter your phone number",
    phoneLength: "Phone number should be 11 characters long",
    identityCard: "Agent Numbers",
    identityCardPlaceholder: "Please enter your agent number",
    birthDate: "Birth Date",
    birthDatePlaceholder: "Select your birthdate",
    userStatus: "User Status",
    userStatusPlaceholder: "Select user status",
    weixin: "WeChat",
    weixinPlaceholder: "Enter your WeChat ID",
    qq: "QQ",
    qqPlaceholder: "Enter your QQ ID",
    email: "Email",
    emailPlaceholder: "Enter your email",
    emailError: "Please enter a valid email address",
    regions: "RegionScope",
    regionsPlaceholder: "Select your region permissions",
    photoUrl: "Avatar",
    updateUserName: "Modified by",
    registerDate: "RegistrationDate",
    updateDate: "ModifiedDate",
    loginDate: "LoginDate",
    loginNum: "LoginCount",

    resetPassTxt: "Are you sure to reset this user's password? New password: {newPass}",
  },
  // Role Management
  RoleView: {
    name: "Name",
    namePlaceholder: "Enter role name",
    nameEn: "Name (En)",
    nameEnPlaceholder: "Enter role name (English)",
    remark: "Role Description",
    remarkPlaceholder: "Enter role description",
    createTime: "Creation Time",
    roleMgs:
      "Query is the basic permission displayed on the page, pay attention to the query authority level of the support ticket!",
  },
  // Menu Management
  MenuView: {
    parent: "Parent Menu",
    parentPlaceholder: "Enter parent menu, leave blank for top-level menu",
    name: "Menu Name",
    namePlaceholder: "Enter menu name",
    nameEn: "Name (En)",
    nameEnPlaceholder: "Enter menu name (English)",
    symbol: "Symbol",
    symbolPlaceholder: "Enter permission symbol, ignore for top-level menu",
    icon: "Icon",
    iconPlaceholder: "Select menu icon",
    resourceType: "Type",
    href: "Route",
    hrefPlaceholder: "Enter route address, ignore for third-level button",
    sort: "Sort",
    sortPlaceholder: "Enter sort, must be a number",
    remark: "Remark",
    remarkPlaceholder: "Enter remark",
  },
  // Dictionary Management
  DictView: {
    // 查询条件
    dictNameQuery: "Name",
    dictNameQueryPlaceholder: "Enter dictionary name",

    // 表格
    dictCode: "Code",
    dictCodePlaceholder: "Enter dictionary code",
    dictName: "Name",
    dictNamePlaceholder: "Enter dictionary name",
    nameEn: "Name (En)",
    nameEnPlaceholder: "Enter dictionary name (English)",
    dictRemark: "Remark",
    dictRemarkPlaceholder: "Enter remark",
    createUser: "Creator",
    createUserPlaceholder: "Select creator",
    createTime: "Creation Time",
    type: "Type",
    typePlaceholder: "Please select category",

    // 新增/修改/详情
    divider: "Dictionary List Details",
    dictKey: "Key",
    dictKeyPlaceholder: "Enter dictionary key",
    keyEn: "Key (En)",
    keyEnPlaceholder: "Enter key (English)",
    dictValue: "Value",
    dictValuePlaceholder: "Enter unique dictionary value",
    dictDetailRemark: "Remark",
    dictDetailRemarkPlaceholder: "Enter dictionary remark",
    dictEnable: "Enable",
    sort: "Sort",
  },
  Log: {
    access: "Access Log",
    log: "Operation Log",

    name: "Log Name",
    ip: "IP Address",
    opAddress: "OpAddress",
    opBrowser: "OpBrowser",
    opOs: "OpOs",
    opTime: "OpTime",
    opUser: "OpUser",
    exeStatus: "ExeStatus",
    ok: "Success",
    err: "Lose",
    exeStatusMgs: "Please select log status",

    detailMsg: "Log Record",
    className: "ClassName",
    methodName: "MethodName",
    paramJson: "paramJson",
    resultJson: "resultJson",

    errDetailMsg: "Err Log",
    dateTime: "Log Date",
    logPigeonhole: "Log Pigeonhole",
    noLogPigeonhole: "No abnormal archive logs are generated. Procedure",
    logNew: "Latest Exception Log",

    user: "Operator",
    userPlaceholder: "Select operator",
    ipPlaceholder: "Enter IP address",
    sourceDict: "Source Module",
    sourceDictPlaceholder: "Select source module",
    operate: "Operation",
    operateTime: "Time",
    result: "Result",
    resultPlaceholder: "Select operation result",

    spendTime: "Spend",
    resultValue: "Result Value",
    parameter: "Parameters",
    method: "Method",
    url: "Request URL",

    logDetail: "Log Details",
    no: "None",

    week: "Recent weekly statistics",
    pieChart: "Proportion statistics",
  },
  myCentre: {
    user: "Profile",
    resource: "My Permissions",
    log: "My Logs",
  },
  // Translation
  notice: {
    title: "Platform Inbox",
    message: "Inbox",

    tab: "Inform",
    classify: "Notification Classify",
    allNotice: "All Notifications",
    messageList: "Message List",
    myMessage: "My Messages",
  },
  // System Configuration
  sysConfig: {
    category: "Type",
    categoryPlaceholder: "Please select a type",
    configKey: "Key",
    keyPlaceholder: "Please enter a configuration key",
    configValue: "Value",
    valuePlaceholder: "Please enter a configuration value",
    remarks: "Notes",
    remarksPlaceholder: "Please enter notes",
    createTime: "Creation Time",
    updateTime: "Modification Time",
    createUser: "Creator",
    updateUser: "Modifier",

    system: {
      title: "Basic configuration",
      title1: "Background cache configuration",
      passLabel: "Default password",
      msg: "Clearing Redis cache, please exercise caution!"
    },

    file: {
      title: "File Configuration",
      title1: "File cleanup list",
      dateLabel: "File date",
      butt: "Cleans files for a specified date range",
      size: "size",
      time: "Upload time",

      msgTitle: "File cleaning",
      msg: "Cleaning system files, please exercise caution!",
      msg1: "System files are being cleared. The date span is large. The clearing time is too long.",
    },

    front: {
      msg: "Note that the size of the foreground background picture (1800 x 400 is recommended) will affect the display, you can upload and save the effect in the foreground, you can make corresponding adjustments to the length and width of the picture!",
      title: "Title",
      titleEn: "Title / Eng",
      txt: "Description",
      txtEn: "Description / Eng",
      img: "Background Picture",
      imgEn: "Background Picture / Eng",
    },
    statistics: {
      title:
        "Title support ticket statistics about the region support ticket data display associated with the region ID for binding.",
    },
    email: {
      alertTitle: "Mail Title Configuration",
      title: "Title",
      titlePlaceholder: "Please enter the mail title (in Chinese)",
      titleEnPlaceholder: "Please enter the mail title (in English)",
      titleTab: "Subtitle",
      titleTabPlaceholder: "Please enter the mail subtitle (in Chinese)",
      titleEnTabPlaceholder: "Please enter the mail subtitle (in English)",
      alertTitleTow: "Mail Content Template Configuration",
      titleDrawer: "Mail Content Template Configuration",
      alertDrawer:
        "Note: Braces are placeholders, and some content is dynamically concatenated with braces",
      labelDrawer: "Template Content (in Chinese)",
      labelEnDrawer: "Template Content (in English)",
    },
    emailMgs: {
      REGISTER_CONTENT: "User Registration",
      SET_EMAIL_CONTENT: "Mail Update",
      TICKET_SUBMIT: "Support Ticket Submission",
      TICKET_END: "Ticket Support Ticket Has Ended",
      TICKET_NEW: "Support Ticket Process Update",
      ASSIGN_TICKET: "Designated Support Ticket",
      CAREO_TICKET: "Transfer Support Ticket",
      CLIENT_TICKET: "Customer Service Reply",
      SERVICE_TICKET: "Customer Reply Notification",
      SEND_VERIFY: "Send Email Verification Code",
      SID_SUBMIT: "Dealer Support Ticket Submission",
      SID_SERVICE: "Dealer Message",
      SID_CLIENT: "Dealer Handler Message",
      SID_END: "Dealer Support Ticket End",
      SID_ASSIGN: "Designated Dealer Support Ticket",
      SID_CAREO: "Transfer Dealer Support Ticket",

      USER_RESET_PASS: "User password reset notification",
      SEND_PASS_VERIFY: "Password recovery notification",
      SET_ACCOUNT: "Change email verification code",
    },
    flow: {
      header: "Support Ticket Process Configuration",
      types: {
        type: "Type of after-sales service",
        tui: "Returns & Exchanges",
        wei: "Return for repair",
      },
      formHeader: "Template Forms",
      formMgs: "Template language configuration is available below",
      noMgs: "Not Yet Established",
      setMgs:
        "Please set up this template in the distribution list to complete the initialization first!",
      setDataMgs: "Please select the template first！",

      tabHeadZh: "Title（zh）",
      tabHeadEn: "Title（en）",
      contentZh: "content（zh）",
      contentEn: "content（en）",
    },
  },
  // 会话管理
  session: {
    title1: "Current Sessions",
    title2: "Maximum Issued Tokens",
    title3: "Added Within 1 Hour",
    title4: "Sys/B/C Share",

    tab1: "SYS Session",
    tab2: "B-Side Sessions",
    tab3: "C-Side Sessions",

    avatar: "Avatar",
    acc: "Account",
    nickname: "Name",
    loginBrowser: "LoginBrowser",
    loginIp: "LoginIp",
    loginOs: "LoginOs",
    loginTime: "LoginTime",
    tokenNum: "TokenNum",

    tokenButton: "Token List",
    tokenForceButton: "Forced Retreat",

    forceListButton: "Batch Forcing",
    force: "Session Forcing",
    forceMsg: "Are you sure you want to force the user to log out？",

    expireTime: "Valid (30 Days)",
    tokenStr: "Token",
  },

  // ------ Client Page: Basic Management ------
  // Ticket Type Management
  problemView: {
    title: "Type",
    titlePlaceholder: "Enter Ticket type",
    titleEn: "Type（En）",
    titleEnPlaceholder: "Enter Ticket type in English",
    remarks: "Description",
    remarksPlaceholder: "Enter description of Ticket type",
    remarksEn: "Description（En）",
    remarksEnPlaceholder: "Enter description of Ticket type in English",
    icon: "Icon",
    iconPlaceholder: "Select icon",
    enable: "Enable",
    enablePlaceholder: "Select whether to enable",
    sortCode: "sortCode",
  },
  // Message Announcement Management
  MessageView: {
    title: "Title",
    titlePlaceholder: "Enter notification title",
    titleEn: "Title (En)",
    titleEnPlaceholder: "Enter notification title in English",
    content: "Content",
    contentPlaceholder: "Enter notification content",
    contentEn: "Content (En)",
    contentEnPlaceholder: "Enter notification content in English",
    type: "Type",
    typePlaceholder: "Select notification type",
    pushTime: "Schedule Push",
    pushName: "Push Type",
    pushNamePlaceholder: "Select push type",
    goTime: "Push Time",
    startDate: "Start Date",
    endDate: "End Date",
    createUser: "Creator",
    createTime: "Creation Time",
    enable: "Enable",
  },
  // Region Management
  RegionView: {
    title: "Region Data Sheet",
    name: "Region (Zh)",
    namePlaceholder: "Enter region/Chinese",
    code: "Abbreviation",
    codePlaceholder: "Enter abbreviation",
    enName: "Region (En)",
    enNamePlaceholder: "Enter region/English",
    remarks: "Remark",
    remarksPlaceholder: "Enter remark",
    enable: "Enable",
  },
  // Email Management
  MailView: {
    buyDitchName: "Email",
    buyDitchNamePlaceholder: "Select email name",
    host: "Server",
    hostPlaceholder: "Enter server",
    username: "Address",
    usernamePlaceholder: "Enter address",
    password: "Authorization Code",
    passwordPlaceholder: "Enter authorization code",
    port: "Port",
    portPlaceholder: "Enter port",
    protocol: "SMTP",
    protocolPlaceholder: "Enter sMTP protocol",
    defaultEncoding: "Encoding",
    defaultEncodingPlaceholder: "Enter encoding",
    remarks: "Remarks",
    remarksPlaceholder: "Enter remarks",
    createUser: "Creator",
    createTime: "Creation Time",
    isDefault: "Non-Primary",
  },
  // clientSideb
  clientSideb: {
    code: "Code",
    codePlaceholder: "Enter customer code",
    name: "Name",
    namePlaceholder: "Enter customer name",
    abbreviation: "Abbreviation",
    abbreviationPlaceholder: "Enter customer abbreviation",
    enable: "Enable",
  },
  // issueRule
  issueRule: {
    kindDict: "Category",
    kindDictPlaceholder: "Select issue category",
    typeDict: "Resource",
    typeDictPlaceholder: "Please select a resource type",
    title: "Title",
    titlePlaceholder: "Enter Chinese/English title",
    titleZh: "Title (zh)",
    titleZhPlaceholder: "Enter chinese title",
    titleEn: "Title (en)",
    titleEnPlaceholder: "Enter english title",
    contentZh: "Content (zh)",
    contentZhPlaceholder: "Enter chinese content",
    contentEn: "Content (en)",
    contentEnPlaceholder: "Enter english content",
    remarks: "Remark",
    remarksPlaceholder: "Enter remarks",
    createUser: "Created By",
    createTime: "Created At",
    enable: "Enable",
  },
  // 工单消息预设
  flowMessage: {
    category: "category",
    categoryPlaceholder: "Please select a default category",
    content: "Default content",
    contentPlaceholder: "Please enter the preset content",

    flowRemark: "Private comment message reply is enabled!",
    remark: "Private Note",
    remarkPlaceholder:
      "Please enter the comment information for this message...",
  },
  // 币种资料
  currency: {
    selectMgs: "Keyword ",
    selectMgsPlaceholder: "Please enter keywords ",

    name: "Name",
    namePlaceholder: "Please enter name/Chinese ",
    nameEn: "Name (en)",
    nameEnPlaceholder: "Please enter name/English ",
    code: "Code ",
    codePlaceholder: "Please enter codes (e.g. GBP, CNY) ",
    symbol: "Symbol",
    symbolPlaceholder: "Please enter a symbol (e.g., ￥) ",
  },

  // ------ SPU Data: Product Management ------
  // Product Management
  SpuView: {
    spuNameQuery: "Name",
    spuNameQueryPlaceholder: "Enter product name / English name",

    spuCode: "Material Code",
    spuCodePlaceholder: "Enter product Material Code",
    spuName: "Product",
    spuNamePlaceholder: "Enter product name",
    nameEn: "Name (En)",
    nameEnPlaceholder: "Enter product name (English)",
    spuImg: "Image",
    series: "Series",
    seriesPlaceholder: "Select product series",
    function: "Function",
    functionPlaceholder: "Select product function",
    specs: "Specifications",
    specsPlaceholder: "Enter product specifications",
    remarks: "Description",
    remarksPlaceholder: "Enter product description",
    remarksEn: "Description (En)",
    remarksEnPlaceholder: "Enter product description (English)",
    createUser: "Creator",
    createUserPlaceholder: "creator",
    createTime: "Creation",
    enable: "Status",
  },
  // Product Series Management
  SeriesView: {
    nameQuery: "Name",
    nameQueryPlaceholder: "Enter product series code or name",

    barCode: "Series Code",
    barCodePlaceholder: "Enter series code",
    name: "Series Name",
    namePlaceholder: "Enter series name",
    nameEn: "Name (En)",
    nameEnPlaceholder: "Enter series name (English)",
  },
  // Product Function Management
  FunctionView: {
    nameQuery: "Name",
    nameQueryPlaceholder: "Enter product function code or name",

    barCode: "Function Code",
    barCodePlaceholder: "Enter function code",
    name: "Function Name",
    namePlaceholder: "Enter function name",
    nameEn: "Name (En)",
    nameEnPlaceholder: "Enter function name (English)",
  },
  ticketAllData:"All",
  ticketNum:"Quantity",
  ticketAllTime:"Duration(total)",
  ticketAverageTime:"Duration(average)",
  ResponseTimes:"Response times",
  Ticket:'Ticket',
  DistributorTicket:'Distributor Ticket',
  // ------ SPU Ticket: Ticket Ticket Management ------
  // Ticket Ticket Management
  AfterSpu: {
    IssueFeedback: "Issue Feedback",
    AfterSalesWorkOrder: "After Sales Support Ticket",
    AfterSalesInformation: "After Sales Information",
    familyTitle: "User Profile",

    ProofPurchaseUploaded: "Proof of purchase has been uploaded",
    ProofPurchaseUnUploaded: "No proof of purchase uploaded",

    recover:'Reopen',
    recoverTips: "Do you want to reopen the ticket?" ,
    recoverTitle: 'Restore Ticket',

    viewInvoiceNo: "No Invoice Yet",
    viewInvoice: "View Invoice",
    invoice: "Invoice",
    cTicketMessage: "Ticket Message",
    cUserMessage: "Customer Ticket Problem",

    // Translation
    sourceDictName: "Source",
    sourceDictNamePlaceholder: "Please select the source type",

    afterOrderNumber: "Ticket No.",
    Quantity:"Quantity",
    afterOrderNumberPlaceholder: "Enter Ticket Ticket No.",
    createDay: "Duration",
    createDayUnit: "Day(s)",
    problemTitle: "Type",
    problemTitlePlaceholder: "Select Ticket Type",
    spuName: "Product",
    spuNamePlaceholder: "Select product Product",
    function: "Function",
    functionPlaceholder: "Select product function",
    series: "Series",
    seriesPlaceholder: "Select product series",

    familyName: "Customer",
    familyNamePlaceholder: "Enter customer name",
    CustomerWorkOrder: "Customer",
    familyPlaceholder: "Please select Customer",
    summary: "Summary",
    summaryPlaceholder: "Enter Ticket problem summary",
    email: "Email",
    emailPlaceholder: "Enter customer email",
    spuSequence: "Serial Number",
    spuSequencePlaceholder: "Enter product Serial Number",
    spuSequenceSize: "The serial number must be 10 digits",
    buyTime: "Date of purchase",
    buyTimePlaceholder: "Purchase from",
    buyDitchName: "Purchase From",
    buyDitchNamePlaceholder: "Select purchase Purchase From",
    ditchRemarks: "Retailer Name",
    remark:"Remarks",
    ditchRemarksPlaceholder: "Please enter a retailer name",
    remarkPlaceholder: "Please enter  remarks",
    region: "Country",
    regionPlaceholder: "Select Country",
    prove: "Proof of Purchase",
    uploadProve: "Upload Proof",
    provePlaceholder: "Enter purchase proof",
    proveNo: "Without uploading",
    videoUrl: "Video URL",
    videoUrlPlaceholder: "Enter video URL",
    detail: "Details",
    detailPlaceholder: "Enter problem details",

    remarks: "After Remarks",
    remarksPlaceholder: "Enter ticket remarks",
    createTime: "Time",
    createDate: "Date",
    revampTime: "Modification",
    finishTime: "Completion",
    revokedTime: "RevokedTime",
    disposeUser: "Agent",
    alterUser: "Transfer",
    disposeUserPlaceholder: "Select",
    disposeRegion: "Region",
    disposeRole: "Role",
    afterStatus: "Status",
    afterStatusPlaceholder: "Select ticket status",

    // Button prompt messages
    receiveTxt: "Are you sure you want to receive this Ticket ticket?",
    transferTxt:
      "Are you sure you want to cancel the transfer of this Ticket ticket?",
    disposeUserError: "Agent cannot be empty!",
    alterUserError: "Transfer Agent cannot be empty!",
    reopenTitle: "Reopen",
    reopenTxt: "Whether to re-open this support ticket for processing？",

    // Specify / Transfer Title
    appointTitle: "Carrying out assigned support tickets",
    transferTitle: "Support ticket referrals made",

    // Details / Modify / Add Page
    tagOne: "Ticket Ticket - Product Information",
    tagTwo: "Ticket Ticket - Agent",
    tagThree: "Ticket Ticket - Details",

    // Progress
    flow: {
      title: "Ticket: ",
      user: "User: ",
      service: "Customer Service: ",
      summarize: "Summary: ",
      describe: "Description: ",
      reference: "Reference Image",

      // Logistics information entry in the process
      fillInLogistics: "Fill in Logistics",
      ditchStatus: "After Status",
      ditchStatusPlaceholder: "Select Ticket status",
      logistics: "Company",
      logisticsPlaceholder: "Enter logistics company",
      logisticsNumber: "Logistics Ticket",
      logisticsNumberPlaceholder: "Enter logistics number",
      sendoutDate: "Date",
      sendoutDatePlaceholder: "Select dispatch date",
      remarks: "Remarks",
      remarksPlaceholder: "Enter logistics remarks",

      // New ticket event
      event: "Create Ticket Ticket Event",
      ditchName: "Type",
      ditchNamePlaceholder: "Select Ticket type",
      newDitchStatus: "Status",
      newDitchStatusPlaceholder: "Select process status",
      newRemarks: "Remarks",
      newRemarksPlaceholder: "Enter process remarks",

      // Reply pop-up
      formTitle: "Summary",
      formTitlePlaceholder: "Enter summary",
      formDescribe: "Description",
      formDescribePlaceholder: "Enter description",
      uploadTitle: "Image Upload",

      formTitleError: "Please enter both summary and description!",
      endTag: "This Ticket ticket has ended",
    },

    // Event Records and Costs
    affair: {
      event: "Events and Records",
      eventFormTitle: "Process Data Form",
      addEvent: "Add Process Data",
      finishTxt: "This operation will end the process. Continue?",

      cost: "Costs and Expenses",
      costFormTitle: "Costs and Expenses Form",
      addCost: "Add Costs and Expenses",
      upCost: "Modify Costs and Expenses",

      // Event Records
      ditchStatus: "Status",
      ditchStatusPlaceholder: "Select process status",
      expressage: "Logistics",
      expressagePlaceholder: "Enter logistics company",
      expressageNumber: "Logistics",
      expressageNumberPlaceholder: "Enter logistics number",
      numberPlaceholder: "number",
      sendoutDate: "Send/Receipt",
      expressageStatus: "Status",
      expressageStatusPlaceholder: "Select logistics status",

      affairNumber: "Event Code",
      ditchName: "Type",
      ditchNamePlaceholder: "Select event type",
      endTag: "This process has ended",
      selectDataRange:"Date Range",
      sendoutDateNew: "Send-out",
      sendoutDateNewPlaceholder: "Select send-out date",
      signDate: "Receipt",
      signDatePlaceholder: "Select receipt date",
      freight: "Amount",
      freightPlaceholder: "Enter freight amount",
      AverageTime: "Average Processing Time (day)",
      // Costs and Expenses
      ditchNameStr: "Type",
      ditchNameStrPlaceholder: "Select cost type",
      money: "Amount",
      moneyPlaceholder: "amount",

      costType: "Classification",
      ticketIncidents: "Ticket Event - Costs and Expenses",
      ticketOther: "Other Ticket - Costs and Expenses",
      supplement:
        "This cost is for supplementary ticket expenses, unrelated to Ticket ticket events...",

      remarks: "Remarks",
      remarksPlaceholder: "Enter remarks",
      createTime: "Creation Time",
    },

    // Share
    share: {
      title: "Ticket Ticket - Share",
      shareTime: "Period",
      threeDay: "ThreeDays",
      sevenDay: "SevenDays",
      thirtyDay: "ThirtyDays",
      permanent: "Permanently",
      shareLink: "Share Link",
      tip1:'Please check within the time validity of the link.',
      tip2:'You need to stay logged in when accessing the shared link to make enquiry.',
      tip3: "Only after-sales back-office users can query support ticket details based on the shared link.",
    },

    // 合并组件
    merge: {
      title: "Merge Ticket",
      mergeTitle: "Master Support Ticket Details",
      mergeTitle1: "Selecting Sub-Support Tickets for Consolidation",

      subTitle: "Sub-support ticket details",
      subTitleDetail: "Click to view related support tickets",

      mergeTip: "Cancellation of the merger",
      mergeTipTxt:
        "Confirmation of cancellation and merger of this support ticket？",
      mergeTip1: "Merge Ticket",
      mergeTipTxt1:
        "Confirmation of merging the selected support ticket into the current support ticket？",
      mergeTip2: "Support Ticket Alerts",
      mergeTipTxt2:
        "This support ticket has been merged and can be jumped to the main support ticket for enquiry and synchronisation of subsequent sales!",
    },
  },
  // Ticket Ticket Transfer Records
  Careof: {
    tisTxt: "The transferor and transferee cannot be the same!",
    afterOrderNumber: "Ticket No.",
    afterOrderNumberPlaceholder: "Enter ticket",
    problemTitle: "Type",
    problemTitlePlaceholder: "Select ticket type",
    spuName: "Product",
    spuNamePlaceholder: "Select product Product",
    function: "Function",
    functionPlaceholder: "Select product function",
    series: "Series",
    seriesPlaceholder: "Select product series",

    familyName: "Customer",
    familyNamePlaceholder: "Enter customer name",
    buyDitchName: "Purchase From",
    buyDitchNamePlaceholder: "Select purchase purchase from",
    region: "Region",
    regionPlaceholder: "Please select a region",
    agoUser: "Transferor",
    agoUserPlaceholder: "Select transferor",
    nowUser: "Transferee",
    nowUserPlaceholder: "Select transferee",
    careofStatus: "Status",
    careofStatusPlaceholder: "Select transfer status",

    summary: "Summary",
    disposeUser: "User",
    createTime: "Transfer",
    revampTime: "Processing",

    transferTxt:
      "Are you sure you want to cancel the transfer of this Ticket ticket?",
    receiveTxt:
      "Are you sure you want to accept the transfer of this Ticket ticket?",
    refuseTxt:
      "Are you sure you want to reject the transfer of this Ticket ticket?",

    // Details
    afterTitle: "Ticket Ticket Transfer",

    // Initiate Ticket Ticket - Transfer Application
    transferTitle: "Initiate Ticket Ticket - Transfer Application",
    // Ticket Ticket - In Transfer
    transferingTitle: "Ticket Ticket - In Transfer",
    // Ticket Ticket - Transfer Success (Ticket Agent has been changed)
    transferSuccessTitle:
      "Ticket Ticket - Transfer Success (Ticket Agent has been changed)",
    // Ticket Ticket - Transfer Rejected (Transferee rejected the ticket transfer)
    transferRefusedTitle:
      "Ticket Ticket - Transfer Rejected (Transferee rejected the ticket transfer)",
    // Ticket Ticket - Transfer Canceled by Transferee
    transferCancelTitle: "Ticket Ticket - Transfer Canceled by Transferee",
    // Ticket Ticket - Transfer Result
    transferResultTitle: "Ticket Ticket - Transfer Result",
    // Ticket Ticket - Transfer End
    transferEndTitle: "Ticket Ticket - Transfer End",
  },
  // Chart Statistics
  SpuChart: {
    title: "Telematics",
    monthSpu: {
      name: "Product Monthly Chart",
      selectData: "Month",
      selectDataPlaceholder: "Select month",
      number: "Count",
      numberPlaceholder: "Enter count",
      numberList: {
        five: "5 items",
        ten: "10 items",
        fifteen: "15 items",
        twenty: "20 items",
      },
      afterQuantity: "Ticket Quantity",

      daterange: "Month",
      spu: "Product Name",
    },
    monthAfter: {
      name: "Monthly Ticket Chart",
      selectData: "Year",
      selectDataPlaceholder: "Select query year",
    },
    regionSpu: {
      name: "Regional Ticket Chart",
      selectData: "Year",
      selectDataPlaceholder: "Select query year",
      region: "Region",
      regionPlaceholder: "Please select a region",
    },
  },
  // Ticket Dashboard
  Board: {
    // Translation
    boardTitle: "Ticket processing support ticket data statistics",

    threeDayQuantity: "Number of Tickets in 1 ≤ 3 Days",
    sevenDayQuantity: "Number of Tickets in 3 ≤ 7 Days",
    bigSevenDayQuantity: "Number of Tickets in 7 ≤ 15 Days",
    bigFifteenDayQuantity: "Number of Tickets in 15 ≤ 30 Days",
    monthOneDayQuantity: "Number of Tickets in 1 ≤ 2 Months",
    allQuantity: "Processing the number of total tickets",

    allProportion: "Ticket Tickets: ",
  },

  // ------ Client View: Customer Ticket / Inquiry ------
  // Customer Ticket Process Form Page
  Clientele: {
    NeoStr:"NEO Stream and ZEN Stream users should check ifi's GUI, the local update driver is only available for Windows PC.",
    proveFilePlaceholder:'Please upload document proof',
    header: "Ticket",
    afterTicketInfo:'This post-sales ticket form is used by customers to submit service requests for products. By filling out the form, you can provide detailed information about the problem you are experiencing and provide necessary information so that the after-sales service personnel can quickly locate and resolve the issue. The form includes four main sections: basic information, after-sales information, problem details, and attachment upload.' ,
    lookUserProfile: 'View personal information',
    afterProblem: "Ticket Type",
    customer: "Customer information",
    spuMessage: "Ticket Details",
    ticketMessage: "Ticket Information",

    selectSpu: "Select",
    fillIn: "Information",
    afterSubmit: "Submission",

    // Ticket Ticket Fill-in Form
    spuName: "Product",
    spuPlaceholder: "Please select a product",
    function: "Product Function",
    series: "Series",
    remarks: "Description",
    cardHeader: "Please fill in the ticket ticket",

    name: "First Name",
    namePlaceholder: "Enter your first name",
    family: "Last Name",
    familyNamePlaceholder: "Enter your last name",

    email: "Email",
    emailPlaceholder: "Enter your email",
    emailError: "Please enter a valid email address",
    spuSequence: "Serial Number",
    spuSequencePlaceholder: "Enter product serial number",
    buyTime: "Date of Purchase",
    buyTimePlaceholder: "Select purchase date of purchase",
    buyDitchName: "Purchase From",
    buyDitchNamePlaceholder: "Select purchase purchase From",
    ditchRemarks: "Remarks",
    ditchRemarksPlaceholder: "Please enter a retailer name",
    region: "Region",
    regionPlaceholder: "Please select a region",
    summary: "Subject",
    summaryPlaceholder: "Enter ticket problem subject",
    detail: "Details",
    detailPlaceholder: "Enter problem details",
    prove: "Proof of Purchase",
    provePlaceholder: "Enter purchase proof",
    videoUrl: "Video URL",
    videoUrlPlaceholder: "Enter video link related to product issue",
    uploadTitle: "Upload",
    andCheck: "I agree to receive product information and promotions from iFi",
    check: "Please check the above three items",
    checkMgs: "Please read the ticket policy and terms",
    emailNoNll: "Email verification code cannot be empty!",

    // Ticket Ticket Submission Page
    afterSubmitTitle: "Ticket Ticket Notification",

    afterSubmitSuccess: "The support ticket is submitted successfully",
    afterSubmitErr: "Failed to submit the support ticket",
    afterSubmitSuccessButton: "Continue",

    afterSubmitError: "Failed",
    afterSubmitErrorTips:
      "Network exception, submission error, please try again later",

    // 客户 v2.0 更新
    iFiHeader: "iFi Support",

    help: "Get the assistance you need",
    contact:
      "Please provide us with some specific information, and we will offer you applicable solutions. You can reach us through the following methods.",

    afterType: "Ticket type",
    selectProduct: "Select product",
    product: "Ticket Product",
    afterOrder: "Ticket",
    afterTypeTips: "Please check the options available to you",

    afterResult: "Result notification",

    verifyCode: "verifyCode",
    verifyCodePlaceholder: "Please enter verification code",
    clickCode: "Click to refresh the code!",
    emailVerifyCode: "Verification Code",
    emailVerifyCodePlaceholder: "Please enter email verification code",
    CodeTime:
      "Email verification code is valid within 30 minutes, expired need to re-obtain!",
    goEmailCode:
      "Click to initiate email verification upon verification code approval!",
    getEmailCode: "Confirm",
    emailNull: "The mailbox is empty or the mailbox format is incorrect!",
    verifyCodeNull: "Verification code cannot be empty!",
    emailNo:
      "Email does not match the email address used for sending the verification code!",
    codeIsNull:
      "Please obtain the verification code before submitting the support ticket!",

    firmwareStr: "Is the firmware up to date?",
    mobileStr: "Have you tried the device on another mobile/PC?",
    driverStr:
      "Have you downloaded and installed the correct iFi driver?",
  },
  // Customer Query Ticket Ticket Page
  ClienteleQuery: {
    // help: 'Get the assistance you need',
    // contact: 'You can query the Ticket support ticket information through the Ticket support ticket number below, and contact us for the processing of Ticket support ticket, we will solve the problem for you as soon as possible, thank you for your support!',

    header: "iFi Ticket Ticket Inquiry",
    afterOrderNumber: "Ticket No.",

    // Ticket Information
    spuName: "Product",
    function: "Function",
    series: "Series",

    divider: "Ticket",
    problemTitle: "Type",
    name: "Customer",
    email: "Email",
    spuSequence: "Serial Number",
    buyTime: "Purchase",
    buyDitchName: "Purchase From",
    ditchRemarks: "Remarks",
    region: "Region",
    summary: "Summary",
    detail: "Details",
    afterStatus: "Status",
    disposeUser: "Agent",
    createTime: "Date",

    // Logistics
    ditchStatus: "After Status",
    ditchStatusPlaceholder: "Select ticket status",
    logistics: "Company",
    logisticsPlaceholder: "Enter logistics company",
    logisticsNumber: "Logistics Ticket",
    logisticsNumberPlaceholder: "Enter logistics number",
    sendoutDate: "Date",
    sendoutDatePlaceholder: "Select dispatch date",
    remarksPlaceholder: "Enter logistics remarks",
    signDate: "Receipt",
    signDatePlaceholder: "Select receipt date",
    remarks: "Remarks",

    flow: "Ticket Ticket Process Progress",
    reference: "Reference Image",
    fillInLogistics: "Logistic Information",
    formTitle: "Summary",
    formTitlePlaceholder: "Enter summary",
    formDescribe: "Description",
    formDescribePlaceholder: "Enter description",
    uploadTitle: "Upload",
    reply: "Ticket Ticket Reply",

    // Tips
    queryNull: "Query parameters are empty",
    finishTxt: "This ticket will close, continue?",
    afterEnd: "Ticket ticket has ended",
    revokedTxt: "This operation will discard the ticket ticket. Continue?",
    revokedEnd: "Ticket ticket has been revoked",

    // Ticket Process Box
    flowTitle: "Ticket Process",

    waitDescription: "Waiting for ticket assignment",
    waitDisposeDescription: "Waiting for customer service to process",
    disposeDescription: "Customer Service Processing",
    endDescription: "Ticket Ticket Ended",
    revokedDescription: "User has revoked the ticket",

    finishTitle: "Ticket completed",
    finishDescription: "Ticket ticket completed",

    // Translation
    refundTitle: "Refund Information",
    refund: "RefundAccount",
    refundNoll: "Refund account cannot be empty!",
    refundPlaceholder:
      "Please fill in your refund account, e.g., China Merchants Bank / 878548569654785",
  },
  // 分享页面工单
  Share: {
    invalid:
      "The link is invalid, please regenerate the Ticket support ticket sharing link",
    error: "The link query is abnormal",
  },
  // 用户工单组件信息
  UserTicket: {
    title: "User Profile",
    total: "Total",
    open: "Open",
    closed: "Closed",

    userName: "Ticket Creator",
    userNameSid: "Ticket Creator",

    sysUser: "Support Staff",

    ticketNow: "Current Status",

    userMsg: "Support User Since",
  },

  // ------ sidebAfter:经销商工单 ------
  // 经销商工单页面
  sidebAfter: {
    ConfirmProductInfo: "Confirm product information",
    InputProductOrder: "Fill in product support ticket",
    AddProductOrder: "Add new product support ticket",
    ProductInfo: "Product Information",
    ProductOrderList: "Equipment support ticket list",
    SubmitProductOrder: "Submit product support ticket",
    AfterSaleProductList:'After-sale-product-list',
    afterOrder: 'After-ticket',
    setDate: "RMA Creation Date",
    afterOrderNumber: "Ticket No.",
    sidebName: "Distributor",
    sidebPlaceholder: "Enter distributor code",
    sidebNamePlaceholder: "Select distributor",
    email: "Email",
    emailPlaceholder: "Enter email",
    buyDitchName: "Purchase From",
    buyDitchNamePlaceholder: "Select purchase purchase from",
    channel: "Explain",
    channelPlaceholder: "Enter channel description",
    regionName: "Region",
    regionNamePlaceholder: "Please select a region",
    salesManager: "Sales man",
    salesManagerPlaceholder: "Enter sales sales man",
    summary: "Product Issue",
    summaryPlaceholder: "Enter problem product Issue ... ",
    remarks: "Remarks",
    remarksPlaceholder: "Enter ticket remarks",
    createTime: "Creation Time",
    revampTime: "Modification",
    finishTime: "Completion",
    revokedTime: "Revocation",
    disposeUser: "Agent",
    disposeUserPlaceholder: "Select agent",
    afterStatus: "Status",

    getSpu: "Select Product",
    spuName: "Product",
    spuNamePlaceholder: "Please select a product",
    functionName: "Function",
    seriesName: "Series",
    quantity: "Quantity",
    spuSequence: "Serial Number",
    // spuSequencePlaceholder: 'Enter Serial Number, separate multiple with /',
    spuSequencePlaceholder: "Enter product Serial Number",
    LeaveSomethingHere:"Leave something here",
    spuSequenceRepetition: "Serial Number Repetition",
    buyTime: "Date of Purchase",
    detailRemarks: "Remarks",
    detailRemarksPlaceholder: "Enter remarks",

    spuNumStr: "Number Of Products: {num} Units",

    InvoiceList: "Invoice List",
    InvoiceNo: "No invoice required",

    rules: {
      sideb: "Distributor cannot be empty",
      email: "Email cannot be empty",
      salesManager: "Sales manager cannot be empty",
      region: "Region cannot be empty",
      buyDitch: "Purchase from cannot be empty",
      channel: "Channel description cannot be empty",
      summary: "Problem description cannot be empty",
    },

    message: {
      tableNull:
        "The Ticket product list contains empty values, * fields are required!",
      spuSequenceErr: "Serial Number and quantity are not consistent!",
    },

    tab: {
      flow: "Tickets and Progress",
      event: "Events and Records",
      cost: "Costs and Expenses",
      detail: "Ticket Detail",

      title: "Distributor/Dealer",
    },
    flow: {
      flowTitle: "Ticket Processing Progress",
      flowText: "Distributor",
      leaveTitle: "Replies",
      leaveText: "This ticket has ended. Thank you for your feedback",
    },
  },
  // 经销商工单的开放页
  fromSide: {

    title1: 'RMA Ticket Support Ticket',
    txt1: 'The RMA ticket support ticket is used to meet the after-sales needs of iFi dealers. You can fill in the relevant after-sales product information or use a template to quickly import it to help you quickly solve product problems.',
    title2: 'RMA Operation',
    title3: 'RMA Support Ticket Information',

    choiceTxt: "Please Select",
    sideTxt: "Information",
    selectSpu: "Please select a product first",

    sideTitle: "Information",
    tableTitle: "Expand Ticket Product Details",

    message: {
      spuRepetition:
        "This product is repeated. You can add the Ticket quantity of its products!",
    },
  },
  // 自助服务开放页面
  issueClientele: {
    urlMgs: "Link",
    imgTitle: "Get the assistance you need for your service support",
    imgTxt:
      "Get the latest information about your iFi products, including self-service documents, product guarantees, after-sales maintenance information, etc., to solve the problems you encounter.",

    title: "Choose a way to continue",
    issue: {
      title: "Self-service",
      contact: "- Visit our knowledge base and check common problem solutions",
      contact1:
        "- Committed to providing you with more convenient and efficient self-service",
      contact2: "- Meet your needs and solve your problems",
      button: "Click to Query",
    },
    clientele: {
      title: "Online Support",
      contact:
        "- Online technical service to solve problems encountered in product use",
      contact1:
        "- You can submit a support ticket based on the problems with the product",
      contact2:
        "- After-sales customer service will solve it for you in the first time",
      button: "Click to Consult",
    },

    issueTitle: "Search for more topics",
    issuePlaceholder: "Search support",
    all: "All",
    cardTitle: "Support Documents",
    linkUrl: "Related link",
  },

  // ------ 工单图表 ------
  chart: {
    productPeak: {
      week: "Week: ",
      num: "Number: ",
      currentWeek: "Current Week",
      lastWeek: "Last Week",
      nextWeek: "Next Week",
    },
    ticketTime: {
      dateStr: "Date Range: ",
      all: "Ticket Total",
      avg: "Average Time",
      allTime: "Ticket Total Time",
      day: "Day",
      single: "Single",
    },
  },

  // ------ 前台登录 ------
  auth: {
    loginTitle: "Welcome to iFi",
    registerTitle: "Join iFi and enjoy instantly",

    namePlaceholder: "Please enter your username",
    account: "Email account",
    accountPlaceholder: "Please enter your email account",
    passwordPlaceholder: "Please enter your account password",
    sendAuthVerifyMailPlaceholder: "Enter verification code",
    sendButton: "Send OTP",
    userType: "I am",
    userC: "Customer",
    userB: "Dealer",

    login: "Login",
    register: "Register",

    userDown: "Profile",
    cloeDown: "Clear Cache",
    logOutDown: "Log Out",

    txt: "or",
    getTxt: "Already have an iFi account?",
    getLink: "Go to login",
    setTxt: "Have no account? ",
    setLink: "Create one!",
    passTxt: "Forgot your password?",
    passLink: "Reset",

    loginOk: "Login successful",
    loginError: "Login failed",
    loginNotError: "User is not logged in, please log in first!",

    authDrawer: "My Information",
    ticketDrawer: "Ticket Record",
    userTypeLabel: "User Type",
    userTag1: "Backend User",
    userTag2: "Customer",
    userTag3: "Distributor",
    userTag: "Unknown",
    switchBack: "Switch to Dashboard",

    pass: {
      emailMsg:"Email address",
      accMag:
        "This item is required!",
      mgs1: "Type your Email address!",
      mgs2: "We will send a verification code email to your email address, please check back!",
      mgs3: "You have been verified by email, you can reset your new password",
    },
    UpdatePassword: "Change Password",
    LoginAndSafe: "Login and Security",
    Profile: "Personal Information",
    OrderHistory: "Ticket History",
    InputNewPassword: "Enter New Password",
    CompileNewPassword: "Repeat New Password",
    UpdateEmailAccount: "Update Email Account",
    InputNewEmail: "Enter new email",
    VerifyNewEmail: "Verify new email",
    emailAddr: "Email address",
    nextStep: "Next step",
    emailTips: "We sent a verification email to ",
    InputEmailCode: "Please enter your email verification code",
    Resend: "Resend",
    ResendTips: "Resend it in ",
    passwordVerifyTip:"The password must contain numbers, upper and lower case letters, special characters and not less than 8 characters!",
    repeatPasswordVerifyTip: "Two passwords different entered！",
    inputCorrectEmail:"Please enter the correct email address",
    AccountCancel: "Account Cancellation",
    ConfirmCancel: "Confirm Cancellation",
    cancelAccountTips: "Are you sure you want to cancel your account?",
    cancelAccountTitle: "Account Cancellation",
  },

  // ------ System Monitor Translation ------
  monitor: {
    cpuTitle: "System CPU Monitor",
    cpuTitle1: "Current total CPU usage",
    cpuMsg: "CPU System Usage",
    cpuMsg1: "CPU User Utilisation",
    cpuMsg2: "CPU Current Total Usage",
    cpuMsg3: "CPU Current Waiting Rate",
    cpuMsg4: "CPU current idle rate",

    memoryTitle: "System Memory Information",
    memoryTitle1: "Total System Memory Usage",
    memoryMsg: "Total amount of memory",
    memoryMsg1: "Memory Used",
    memoryMsg2: "Memory Remaining",
    memoryMsg3: "Total System Memory Usage",

    storageTitle: "System storage information",
    storageTitle1: "Total system storage usage",
    storageMsg: "Total Storage",
    storageMsg1: "Storage Used",
    storageMsg2: "Storage Remaining",
    storageMsg3: "Total System Storage Usage",

    upLinkTitle: "Network Information",
    upLinkMsg: "Uplink Rate",
    upLinkMsg1: "Downlink Rate",

    cpuDataTitle: "CPU Monitor",
    cpuDataMsg: "CPU Name",
    cpuDataMsg1: "Number of CPUs",
    cpuDataMsg2: "Number of physical cores of CPU",
    cpuDataMsg3: "Number of CPU logical cores",

    jvmTitle: "JVM Information",
    jvmMsg: "JVM name",
    jvmMsg1: "JVM Version",
    jvmMsg2: "JVM Startup Time",
    jvmMsg3: "JVM runtime length",
    jvmMsg4: "Java version",
    jvmMsg5: "Java Installation Path",

    jvmDataTitle: "JVM Monitoring",
    jvmDataTitle1: "JVM Memory Usage",
    jvmDataMsg: "JVM Total Allocated Memory",
    jvmDataMsg1: "JVM Used Memory",
    jvmDataMsg2: "JVM Remaining Memory",
    jvmDataMsg3: "JVM Memory Usage",

    serverTitle: "Server Information",
    serverMsg: "Server name",
    serverMsg1: "Server Operating System",
    serverMsg2: "Server IP",
    serverMsg3: "Server Architecture",
    }
};
