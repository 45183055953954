import request from '../request'
import {setDownloadFile} from "@/util/system";

// 用户登录
export function login(data) {
    return request({
        url: '/login',
        method: 'post',
        data
    })
}

// 用户退出账号
export function logout() {
    return request({
        url: '/logout',
        method: 'post',
        headers: {
            'tokenStr': window.localStorage.getItem('token')
        }
    })
}

// 获取用户菜单权限
export function getMenuList() {
    return request({
        url: '/resourceInfo/getMenu',
        method: 'get'
    })
}

// 下载文件 - 通过文件路径
export function downloadFileByFilePath(filePath) {
    return request({
        url: '/uploadFile/downloadFileByFilePath',
        method: 'post',
        params: {
            filePath: filePath
        },
        responseType: 'blob'
    }).then(res => {
        // 下载文件
        setDownloadFile(res)
    }).catch(err=>{
        // console.log(err)
    })
}

// 根据名称生成头像 返回编码
export function getAvatarByName(name) {
    return request({
        url: '/uploadFile/getAvatarByName',
        method: 'post',
        params: {
            name: name
        }
    })
}

// 将上传的图片转为Base64编码格式
export function getBase64ByFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
    })
}
