import axios from "axios";
import {ElMessage, ElMessageBox, ElNotification} from "element-plus";
import { getSysLanguage } from "@/util/system";
import { i18n } from "@/main.js";
import { delWinUser } from "@/clientView/auth";
import router from "@/router";
import { getUserTicketById } from "@/http/auth/authUserApi";




const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000,
});

const language = getSysLanguage();

// 请求拦截器: 希望axios每次发送后台请求都带上token
service.interceptors.request.use(
  (config) => {
    // 添加请求头等前置处理
    // const url = config.url.toLowerCase();
    // 如果请求的 URL 包含 "Clientele"，则不携带 token
    // if (!url.includes('clientele') && !url.includes('sidebClientele')) {
    config.headers.token = window.localStorage.getItem("token");
    // }
   // 添加语言请求头 - 语言切换 - zh-CN_中文 en-US_英文 默认英文
   config.headers.language = language;
   // 添加时区请求头
   config.headers.zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
   return config;
  },
  (error) => {
    // 请求错误处理
    // console.log('Request Error:', error)
    return Promise.reject(error);
  }
);

let ElNotificationInStance = null;
// 请求响应拦截器
service.interceptors.response.use(
  (response) => {

    // 未登录触发 重定向到首页 备注：未登录的所有请求会抛出4004
    if(response.data.status === 4004 && location.hash.indexOf!='/ticket/report'){
      window.localStorage.clear()
      router.push('/ticket/report')
      ElMessage.error(i18n.global.t("common.loginExpired"))
      return response;
    }


    // 响应为 下载表格 / 下载文件 - 直接返回
    if (
      response.headers["content-type"] ===
        "application/vnd.ms-excel;charset=utf-8" ||
      response.headers["content-type"] === "application/octet-stream"
    ) {
      return response;
    }
    // 响应后处理
    if (response.status === 200 && response.data.status === 200) {
      return response;
    } else if (response.data.status === 3002) {
      return response; // 客户登录查询状态码 放行组件处理
    } else if (response.data.status === 201) {
      // 表格导入异常 进行放行
      return response;
    } else if (
      response.data.status === 2002 ||
      response.data.status === 2001 ||
      response.data.status === 502 ||
      response.data.status === 2011
    ) {
      getUserVerify(response);
      // 返回一个带有错误信息的Promise，以阻止进一步的链式调用 // 放行（不做任何处理） return response;
      return Promise.reject(new Error(response.data.msg));
    } else if (response.data.status === 4001) {
      // 没有权限 提示 进行刷新本地缓存
      if (!isAccessDenied) accessDenied(response);
    } else if (response.data.status === 499) {
      return response;
    } else {
      ElNotificationInStance && ElNotificationInStance.close();
      ElNotificationInStance = ElNotification({
        title: i18n.global.t("common.dialog.title"),
        message: response.data.msg,
        type: "error",
      });
      return response;
      
    }
  },
  (error) => {
    let err = error.response.data;
    let mgs = err.path + "(" + err.status + "): " + err.message;
    ElNotificationInStance && ElNotificationInStance.close();
    ElNotificationInStance = ElNotification({
      title: i18n.global.t("common.dialog.title"),
      message: err.message,
      type: "info",
    });
    return Promise.reject(error);
  }
);

/**
 * 请求用户身份验证问题
 */
function getUserVerify(response) {
  // 表示登录失效 进行提示
  if (response.data.status === 2002) {
    delWinUser();
    ElMessageBox.alert(
      response.data.msg,
      i18n.global.t("common.message.tipsTitle"),
      {
        confirmButtonText: i18n.global.t("common.button.login"),
        showClose: false,
        callback: (action) => {
          // 刷新 清除本地缓存
          window.localStorage.clear();
          location.reload();
        },
      }
    ).then((r) => {});
    return;
  }
  // 判断工单的客户与当前登录的客户是否一致 不一致则自动跳转到首页
  if (response.data.status === 2011) {
    router.push({ name: "noData" });
  }
}

/**
 * 用户无此权限
 */
let isAccessDenied = false; // 保证每次弹窗只出现一次
function accessDenied(response) {
  isAccessDenied = true;
  getUserTicketById().then((res) => {
    window.localStorage.setItem("account", res.data.data);

    ElMessageBox.confirm(
      response.data.msg,
      i18n.global.t("common.dialog.title"),
      {
        confirmButtonText: i18n.global.t("HomeView.avatarDropdown.setCache"),
        cancelButtonText: i18n.global.t("common.button.goBack"),
        type: "warning",
        center: false,
      }
    )
      .then(() => {
        isAccessDenied = false;
        location.reload(); // 刷新
      })
      .catch(() => {
        isAccessDenied = false;
      });
  });
}

export default service;
