import request from '../request'

/**
 * 登录用户的数据获取API类
 * @returns {*}
 */

// 获取用户的登录状态
export function getAuthUserStatus() {
  return request({
    url: '/authData/getAuthUserStatus',
    method: 'get',
  })
}

// 获取登录用户的最新数据及权限等
export function getUserTicketById() {
  return request({
    url: '/authData/getAuthUser',
    method: 'get',
    params: null
  })
}

// 获取字典列表
export function getDictList() {
  return request({
    url: '/authData/getDictList',
    method: 'get',
  });
}

// 根据主表Code获取子表的对象集合 (通过缓存)
export function getDetailByDictCode(code) {
  return request({
    url: '/authData/getDetailByDictCode',
    method: 'get',
    params: {
      dictCode: code
    }
  });
}
