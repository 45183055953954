import request from '../request'

// ---------- 客户售后页面数据请求 --------------

export function sendVerifyMail(data) {
  return request({
    url: '/clientele/sendVerifyMail',
    method: 'post',
    data
  })
}

// 客户页面一: 售后类型数据
export function listProblemClientele() {
  return request({
    url: '/clientele/listProblemClientele',
    method: 'get'
  })
}

// 客户页面一: 售后类型数据
export function getFunctionClientele() {
  return request({
    url: '/clientele/getFunctionClientele',
    method: 'get'
  })
}

// 客户页面二: 根据产品功能获取产品列表
export function getSpuByFunctionClientele(params) {
  return request({
    url: '/clientele/getSpuByFunctionClientele',
    method: 'get',
    params: params
  });
}

// 客户页面三: 获取字典Code下拉数据
export function getDictPullDownClientele(params) {
  return request({
    url: '/clientele/getDictPullDownClientele',
    method: 'get',
    params: params
  })
}

// 客户页面三: 获取地区下拉数据
export function getRegionPullDown() {
  return request({
    url: '/clientele/getRegionPullDown',
    method: 'get',
  })
}

// 客户页面三: 提交售后申请
export function saveAfterClientele(data) {
  return request({
    url: '/clientele/saveAfterClientele',
    method: 'post',
    data
  })
}

// 根据售后单号查询售后单流程
export function listAfterFlowBySelcetUrlClientele(data) {
  return request({
    url: '/clientele/listAfterFlowBySelcetUrlClientele',
    method: 'post',
    data
  })
}

// 新增客户回复数据
export function saveAfterFlowClientele(data) {
  return request({
    url: '/clientele/saveAfterFlowClientele',
    method: 'post',
    data
  })
}

// 结束工单
export function finishAfterClientele(data) {
  return request({
    url: '/clientele/finishAfterClientele',
    method: 'post',
    data
  })
}

// 撤销工单
export function revokedAfterClientele(data) {
  return request({
    url: '/clientele/revokedAfterClientele',
    method: 'post',
    data
  })
}

// 获取客户页通知数据
export function listMessagePushClientele() {
  return request({
    url: '/clientele/listMessagePushClientele',
    method: 'get',
  })
}

// 新增客户售后表单数据
export function saveAffairClientele(data) {
  return request({
    url: '/clientele/saveAffairClientele',
    method: 'post',
    data
  })
}

// 新增客户售后表单数据 用户填入退款账户数据
export function updateAffairDetail(data) {
  return request({
    url: '/clientele/updateAffairDetail',
    method: 'post',
    data
  })
}

// 根据id获取客户售后流程数据
export function getAffairDetailByIdClientele(params) {
  return request({
    url: '/clientele/getAffairDetailByIdClientele',
    method: 'get',
    params: params
  })
}

// 用户签收
export function signForClientele(data) {
  return request({
    url: '/clientele/signForClientele',
    method: 'post',
    data
  })
}

// 事件确认后的自动新增对应的事件
export function saveInquireAffairClientele(data) {
  return request({
    url: '/clientele/saveInquireAffairClientele',
    method: 'post',
    data
  })
}

// 开放页面获取自助服务数据接口
export function listIssueRuleClientele(data) {
  return request({
    url: '/clientele/listIssueRuleClientele',
    method: 'post',
    data
  })
}
// 获取支持链接数据
export function getIssueRuleUrlClientele(data) {
  return request({
    url: '/clientele/getIssueRuleUrlClientele',
    method: 'post',
    data
  })
}

// 获取文件类型
export function getFileDictList() {
  return request({
    url: '/clientele/getFileDictList',
    method: 'get',
  })
}

// 获取前台数据 图片 标题
export function getSysConfigFront() {
  return request({
    url: '/clientele/getSysConfigFront',
    method: 'get',
  })
}

// 获取产品的功能联级数据
export function getFunctionSpuClientele() {
  return request({
    url: '/clientele/getFunctionSpuClientele',
    method: 'get'
  })
}
