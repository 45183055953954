// ------------------- 以下为 new Date 格式化函数 -------------------

import dayjs from "dayjs";

/**
 * 将 new Date 格式化为 2021-01-01 00:00:00
 * @param elDate
 * @returns {string}
 */
export function getNewYmdhmsStr(elDate) {
    // 将原始日期字符串转换为 Date 对象
    const originalDate = new Date(elDate);
    // 获取年、月、日、时、分、秒
    const year = originalDate.getUTCFullYear();
    const month = originalDate.getUTCMonth() + 1; // 月份是从 0 开始的，需要加 1
    const day = originalDate.getUTCDate();
    const hour = originalDate.getUTCHours() + 8; // 时区问题，需要加 8
    const minute = originalDate.getUTCMinutes();
    const second = originalDate.getUTCSeconds();
    // 构建新的日期字符串
    const formattedDateString = `${year}-${padZero(month)}-${padZero(day)} ${padZero(hour)}:${padZero(minute)}:${padZero(second)}`;
    return formattedDateString;
}

/**
 * 将 new Date 格式化为 yyyy-MM-dd
 * @param elDate
 * @returns {string}
 */
export function getNewYmdStr(elDate) {
    // 将原始日期字符串转换为 Date 对象
    const originalDate = new Date(elDate);
    // 获取年、月、日
    const year = originalDate.getUTCFullYear();
    const month = originalDate.getUTCMonth() + 1; // 月份是从 0 开始的，需要加 1
    const day = originalDate.getUTCDate();
    // 构建新的日期字符串
    const formattedDateString = `${year}-${padZero(month)}-${padZero(day)}`;
    return formattedDateString;
}

/**
 * 将 new Date 格式化为 yyyy-MM
 * @param elDate  时间数据
 * @returns {string} yyyy-MM
 */
export function getNewYmStr(elDate) {
    // 将原始日期字符串转换为 Date 对象
    const originalDate = new Date(elDate);
    // 获取年、月、日
    const year = originalDate.getUTCFullYear();
    // 月份是从 0 开始的，需要加1,
    const month = originalDate.getUTCMonth() + 1;
    // 构建新的日期字符串
    const formattedDateString = `${year}-${padZero(month)}`;
    return formattedDateString;
}

/**
 * 将 new Date 格式化为 yyyy
 * @param elDate 时间数据
 * @returns {string} yyyy
 */
export function getNewYStr(elDate) {
    // 将原始日期字符串转换为 Date 对象
    const originalDate = new Date(elDate);
    // 获取年、月、日
    const year = Number(originalDate.getUTCFullYear());
    return year + '';
}

// ------------------- 以下为辅助函数 -------------------
/**
 * 辅助函数，用于在数字前面补零
 * @param number
 * @returns {string}
 */
function padZero(number) {
    return number.toString().padStart(2, '0');
}

/**
 * 获取日期字符串的星期几
 * @param dateString 日期字符串，格式为 yyyy-MM-dd
 * @returns {string} 星期几 例如：星期一
 */
export function getDayOfWeekInChinese(dateString) {
    // 将日期字符串转换为Date对象
    var date = new Date(dateString);
    // 获取星期几的数值 (0 表示星期日，1 表示星期一，以此类推)
    var dayOfWeek = date.getDay();
    // 将数值转换为对应的中文星期几字符串
    var daysInChinese = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
    var dayOfWeekStringInChinese = daysInChinese[dayOfWeek];
    return dayOfWeekStringInChinese;
}

/**
 * 获取日期字符串的星期几
 * @param dateString 日期字符串，格式为 yyyy-MM-dd
 * @returns {string} 星期几(英文) 例如：Monday Tuesday Wednesday Thursday Friday Saturday Sunday
 */
export function getDayOfWeekInEnglish(dateString) {
    // 将日期字符串转换为Date对象
    var date = new Date(dateString);
    // 获取星期几的数值 (0 表示星期日，1 表示星期一，以此类推)
    var dayOfWeek = date.getDay();
    // 将数值转换为对应的英文星期几字符串
    var daysInEnglish = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var dayOfWeekStringInEnglish = daysInEnglish[dayOfWeek];
    return dayOfWeekStringInEnglish;
}

/**
 * 偏移日期的天数 (向前或向后偏移)
 * - offsetDays(new Date(), -1) 表示获取昨天的日期
 * - offsetDays(new Date('2024-01-08'), -1) 表示获取 2024-01-08 的前一天
 * @param originalDate 原始日期
 * @param days 偏移的天数，正数表示向后偏移，负数表示向前偏移
 * @returns {string} 偏移后的日期字符串，格式为 yyyy-MM-dd
 */
export function offsetDays(originalDate, days) {
    const newDate = new Date(originalDate);
    newDate.setDate(newDate.getDate() + days);
    return getNewYmdStr(newDate);
}

/**
 * 精确偏移天数 包含时分秒
 */
export function offsetDaysAccurate(originalDate, days) {
    const newDate = new Date(originalDate);
    newDate.setDate(newDate.getDate() + days);
    return getNewYmdhmsStr(newDate);
}

/**
 * 精确偏移月份 包含时分秒
 */
export function offsetMonthsAccurate(originalDate, months) {
    const newDate = new Date(originalDate);
    newDate.setMonth(newDate.getMonth() + months);
    return getNewYmdhmsStr(newDate);
}

/**
 * 根据2024年3月4日10:39:36获取时间戳
 */
export function getTimestamp(dateString) {
    return new Date(dateString).getTime();
}

/**
 * 根据时间戳获取时间字符串
 */
export function getDateStr(timestamp) {
    return new Date(timestamp).toLocaleString();
}


export function diffInSeconds(date1, date2) {
    // 获取两个日期对象的时间戳（以毫秒为单位）
    var time1 = date1.getTime();
    var time2 = date2.getTime();

    // 计算时间戳之间的毫秒差，并将其转换为秒数，并取整
    var diffInSeconds = Math.floor(Math.abs((time2 - time1) / 1000));

    return diffInSeconds;
}


/**
 * 日期格式 DD/MM/YYYY
 */
export const enDateFormat = 'DD/MM/YYYY';
export const enDateSign = '/';
/**
 * 日期格式化成 DD/MM/YYYY
 * @param dateString yyyy-MM-dd
 * @returns {string} DD/MM/YYYY
 */
export function formatDate(dateString) {
    // 解析日期字符串
    const date = new Date(dateString);
    // 获取日、月、年
    const day = date.getDate().toString().padStart(2, '0'); // 获取日并确保为两位数字
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 获取月（0-11），加1转为实际月份，并确保为两位数字
    const year = date.getFullYear(); // 获取四位年份
    // 拼接成DDMMYYYY格式
    return `${day}/${month}/${year}`;
}
/**
 * 日期格式化成 yyyy-MM-dd
 * @param dateString DDMMYYYY
 * @returns {string} yyyy-MM-dd
 */
export function convertDateFormat(dateString) {
    // 解析日期字符串
    const date = new Date(dateString);
    // 获取年、月、日
    const year = date.getFullYear(); // 获取四位年份
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 获取月（0-11），加1转为实际月份，并确保为两位数字
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

/**
 * 日期格式化成 DD/MM/YYYY HH:MM:SS
 * @param dateTimeString yyyy-MM-dd HH:MM:SS
 * @returns {string} DD/MM/YYYY HH:MM:SS
 */
export function convertDateTimeFormat(dateTimeString) {
    // 解析日期时间字符串
    const dateTime = new Date(dateTimeString);
    // 获取日、月、年
    const day = dateTime.getDate().toString().padStart(2, '0'); // 获取日并确保为两位数字
    const month = (dateTime.getMonth() + 1).toString().padStart(2, '0'); // 获取月（0-11），加1转为实际月份，并确保为两位数字
    const year = dateTime.getFullYear(); // 获取四位年份
    // 获取时间部分
    const hours = dateTime.getHours().toString().padStart(2, '0');
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');
    const seconds = dateTime.getSeconds().toString().padStart(2, '0');
    // 拼接成DDMMYYYY HH:MM:SS格式
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
}

/**
 * 计算给定时间与当前时间的差值占比
 * @param givenTime
 * @returns {number}
 */
export function calculateTimeDifferenceInHours(givenTime) {
    // 当前时间
    const currentTime = dayjs();
    // 计算两个时间之间的差值（以秒为单位）
    const timeDifferenceInSeconds = currentTime.diff(dayjs(givenTime), 'second');
    const tokenTime = daysToSeconds(30);
    return Number(Math.trunc((timeDifferenceInSeconds / tokenTime) * 100).toFixed(2));
}
export function calculateTimeDifferenceInHoursStr(givenTime) {
    // 当前时间
    const currentTime = dayjs();
    // 计算两个时间之间的差值（以秒为单位）
    let tokenTime = daysToSeconds(30) - currentTime.diff(dayjs(givenTime), 'second');
    const days = Math.floor(tokenTime / 86400);
    tokenTime %= 86400;
    const hours = Math.floor(tokenTime / 3600);
    tokenTime %= 3600;
    const minutes = Math.floor(tokenTime / 60);
    tokenTime %= 60;
    return { days, hours, minutes, tokenTime };
}

/**
 * 指定天数转换为秒数
 * @param days
 * @returns {number}
 */
export function daysToSeconds(days) {
    // 计算给定天数的秒数
    return days * 24 * 60 * 60;
}

/**
 * 计算 开始日期 与 结束日期 相差天数
 */
export function calculateDaysBetweenDates(startDate, endDate) {
    // 创建两个日期对象
    const start = new Date(startDate);
    const end = new Date(endDate);

    // 计算两个日期之间的毫秒差
    const timeDifference = end.getTime() - start.getTime();

    // 将毫秒差转换为天数
    // 返回天数
    return timeDifference / (1000 * 3600 * 24);
}
