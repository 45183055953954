import {createPinia,defineStore} from 'pinia'
import {getSysConfigFront} from "@/http/authApi/clienteleApi";
import {getSysLanguage} from "@/util/system";

 const useSysStore = defineStore('systemStore', {
    state: () => ({ 
        _SysConfig:{
            INDEX_IMG: '',
            INDEX_TITLE: '',
            INDEX_TXT: '',
            COLOR_TEXT: '',
            FRONT_BG_COLOR:'',
        },
        language: getSysLanguage(),
     }),
    getters: {
        SysConfig: (state) => state._SysConfig,
    },
    actions: {
      setSysConfig(SysConfigData) {
       
        this._SysConfig=SysConfigData
          sessionStorage.setItem('SysConfig', JSON.stringify(this._SysConfig))
      
      },
      getSysConfig(){
        let SysConfigData = sessionStorage.getItem('SysConfig')&&(JSON.parse(sessionStorage.getItem('SysConfig')))
        if(SysConfigData){
            this.setSysConfig(SysConfigData)
        }
        getSysConfigFront().then(res => {
            if (res.data.status === 200) {
                 let parseConfigData = this._SysConfig
                 res.data.data.forEach((item) => {
                    if (this.language === 'zh-CN') {
                        parseConfigData[item.configKey] = item.configValue
                    } else {
                        parseConfigData[item.configKey] = item.configValueEn
                    }
                  })
              this.setSysConfig(parseConfigData)
            }
          });
      }
    },
  })

  export default useSysStore;