// --------- 前台 系统相关 -----------

import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import router from "@/router";
import { cWebUrl } from "@/enumbag/StyleEnum";
import { logout } from "@/http/home/api";
import { i18n } from "@/main";
import { getAuthUserStatus } from "@/http/auth/authUserApi";

/**
 * 判断用户是否登录或者过期
 */
export function isUserType() {
  getAuthUserStatus().then((res) => {
    if (res.data.status === 3002) {
      window.localStorage.removeItem("account");
      window.localStorage.removeItem("token");
      // ElMessage({message: res.data.msg, type: 'warning',});
      router.push(cWebUrl).then((r) => {});
      return false;
    } else {
      return true;
    }
  });
}

/**
 * 删除用户信息本地储存
 */
export function delWinUser() {
  window.localStorage.removeItem("account");
  window.localStorage.removeItem("token");
}

/**
 * 清除缓存
 */
export function cloeWinAll() {
  // 清除缓存
  const loading = ElLoading.service({
    lock: true,
    text: i18n.global.t("HomeView.clearCache"),
    background: "rgba(0, 0, 0, 0.7)",
  });
  logout()
    .then((res) => {})
    .finally(() => {
      window.localStorage.clear();

      setTimeout(() => {
        location.reload();
      }, 1000);
    })
    .finally(() => {
      loading.close();
    });
}

/**
 * 退出登录 前台
 */
export function logOutAuth(isTips = true) {
  if (isTips) {
    // 退出登录
    ElMessageBox.confirm(
      i18n.global.t("HomeView.logOutDialog.logoutTips")
    ).then(() => {
      logout().then((res) => {
        if (res.data.status === 200) {
          // 删除浏览器token其数据 跳转到登录页面 提示
          delWinUser();
          router.replace('/auth/login')
          // ElMessage({message: i18n.global.t("HomeView.logOutDialog.logoutSuccess"), type: 'success',})
        //   location.reload();
        }
      });
    });
  } else {
    logout().then((res) => {
      if (res.data.status === 200) {
        // 删除浏览器token其数据 跳转到登录页面 提示
        delWinUser();
        // ElMessage({message: i18n.global.t("HomeView.logOutDialog.logoutSuccess"), type: 'success',})
        // location.reload();
        router.replace('/auth/login')
      }
    });
  }

  // .catch(() => {
  //     ElMessage({message: i18n.global.t("HomeView.logOutDialog.logoutError"), type: 'info',})
  // })
}

export function noAuth() {
      // 退出登录
      ElMessageBox.confirm(
        i18n.global.t("HomeView.logOutDialog.noAuthTip")
      ).then(() => {
        logout().then((res) => {
          if (res.data.status === 200) {
            // 删除浏览器token其数据 跳转到登录页面 提示
            delWinUser();
            // ElMessage({message: i18n.global.t("HomeView.logOutDialog.logoutSuccess"), type: 'success',})
            router.replace('/auth/login')
          }
        });
      }).catch(()=>{
          router.replace('/ticket/report')
      });
}
